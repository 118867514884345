import {useContext} from 'react'
import { Link } from 'react-router-dom';

import FavouritesContext from '../store/favourites-context';
import MeetupList from '../components/meetups/MeetupList';
function Favourites() {
  const favouritesContext = useContext(FavouritesContext)
  let content;
  
  if(favouritesContext.totalFavourites === 0) {
    content = <p>You do not have any favourites yet. Start adding <Link to='/'>some?</Link></p>
  } else {
    content = <MeetupList meetups={favouritesContext.favourites}/>
  }
    return(
    <section>
      <h1>My Favourites</h1>
      {content}
    </section>      

    )
  }
  
  export default Favourites;