import { useContext } from "react";

import classes from "../../css/MeetupItem.module.css";
import Card from "../ui/card";
import FavouritesContext from "../../store/favourites-context";

function MeetupItem(props) {
  const favouritesContext = useContext(FavouritesContext)

  const isFavourite = favouritesContext.isFavourite(props.id)
  function toggleFavourite() {
    if(isFavourite) {
      favouritesContext.removeFavourite(props.id)
    } else {
      favouritesContext.addFavourite({
        id: props.id,
        title: props.title,
        desc: props.desc,
        image: props.image,
        address: props.address
      })
    }
  }

  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.image}>
          <img src={props.image} alt={props.title} />
        </div>
        <div className={classes.content}>
          <h3>{props.title}</h3>
          <address>{props.address}</address>
          <p>{props.desc}</p>
        </div>
        <div className={classes.actions}>
          <button onClick={toggleFavourite}>{isFavourite ? 'Remove from Favourites' : 'Add to Favourites'}</button>
        </div>
      </Card>
    </li>
  );
}

export default MeetupItem;
