import { createContext, useState } from "react";

const FavouritesContext = createContext({
  favourites: [],
  totalFavourites: 0,
  addFavourite: (favouriteMeetup) => {},
  removeFavourite: (meetupID) => {},
  isFavourite: (meetupID) => {},
});

export function FavouritesContextProvider(props) {
  const [userFavourites, setUserFavourites] = useState([]);

  function addFavouriteHandler(favouriteMeetup) {
    setUserFavourites((prev) => {
      return prev.concat(favouriteMeetup);
    });
  }

  function removeFavouriteHandler(meetupID) {
    setUserFavourites((prev) => {
      return prev.filter((m) => m.id !== meetupID);
    });
  }

  function itemIsFavouriteHander(meetupID) {
    return userFavourites.some((m) => m.id === meetupID);
  }

  const context = {
    favourites: userFavourites,
    totalFavourites: userFavourites.length,
    addFavourite: addFavouriteHandler,
    removeFavourite: removeFavouriteHandler,
    isFavourite: itemIsFavouriteHander,
  };

  return (
    <FavouritesContext.Provider value={context}>
      {props.children}
    </FavouritesContext.Provider>
  );
}

export default FavouritesContext;
