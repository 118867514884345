import classes from '../../css/layout.module.css'
import MainNavBar from './MainNav'
function Layout(props) {
    return(
        <div>
            <MainNavBar/>
            <main className={classes.main}>
                {props.children}
            </main>
        </div>
    )
}

export default Layout