import { Link } from "react-router-dom";
import { useContext } from "react";

import classes from "../../css/MainNav.module.css"
import FavouritesContext from "../../store/favourites-context";

function MainNavBar() {
  const favouritesContext = useContext(FavouritesContext)
  return (
    <header className={classes.header}>
      <div className={classes.logo}>React Meetups</div>

      <nav>
        <ul>
          <li>
            <Link to="/">All Meetups</Link>
          </li>

          <li>
            <Link to="/new-meetup">Add New Meetup</Link>
          </li>

          <li>
            <Link to="/favourites">Favourite Meetups
            <span className={classes.badge}>{favouritesContext.totalFavourites}</span>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavBar;
