import NewMeetupForm from "../components/meetups/NewMeetupForm";
import { useNavigate } from 'react-router-dom';

function NewMeetup() {
  const navigate = useNavigate()

  function addMeetUpHandler(meetUpData) {
    fetch('https://react-meetups-8067e-default-rtdb.firebaseio.com/meetups.json',
    {
      method: 'POST',
      body: JSON.stringify(meetUpData),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    ).then(() => {
      navigate('/', {replace: true})
    })
    
  }
  return (
    <section>
      <h1>Add New Meetup</h1>
      <NewMeetupForm onAddMeetup={addMeetUpHandler}/>
    </section>
  );
}

export default NewMeetup;
